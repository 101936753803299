import React from "react";
import tw from "twin.macro";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import WaluIcon from "../images/WL_Logo.png";
import GeluIcon from "../images/Gelu_logo.png";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "images/instagram-icon.svg";
import KoluIcon from "../images/KL_Logo.png";
import styled from 'styled-components';


const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-10 lg:py-16`;

const Column = tw.div`flex items-center justify-center flex-col px-8`;
const Row = tw.div`flex justify-between mt-5`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start mt-2 mb-8`;
const LogoImg = tw.img`w-24`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-2`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;
const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;
const FlandersText = tw.p`text-center mt-5 font-medium tracking-wide text-sm`

export default () => {
  return (
    <Container>
      <Content>
        <Column>
          <FlandersText>Our services</FlandersText>
          <LogoContainer>
            <Link href="https://kolu.be/">
              <LogoImg src={KoluIcon} alt={<LogoText>Kolu.be</LogoText>} />
            </Link>
            <LogoImg src={GeluIcon} alt={<LogoText>Gezondelucht.be</LogoText>}/>
            <Link href="https://walu.be">
              <LogoImg src={WaluIcon} alt={<LogoText>Walu.be</LogoText>} />
            </Link>
          </LogoContainer>
          <Row>
            <LinksContainer>
              <Link href="/privacy">Privacy</Link>
            </LinksContainer>
            <SocialLinksContainer>
              <SocialLink href="https://www.facebook.com/gezondekantoorlucht/">
                <FacebookIcon />
              </SocialLink>
              <SocialLink href="https://www.instagram.com/gezondekantoorlucht.be/">
                <InstagramIcon />
              </SocialLink>
            </SocialLinksContainer>
          </Row>
          <CopyrightText>
            &copy; Copyright 2020, Polling bvba. All Rights Reserved.
          </CopyrightText>
        </Column>
      </Content>
    </Container>
  );
};
