// FlagsProvider.js

// https://medium.com/javascript-in-plain-english/create-feature-flags-for-your-react-app-with-firebase-remote-config-92d17c5e518d
import React, { useEffect, useContext, useState } from "react";
import { firebase } from "./firebase";

const FlagsContext = React.createContext({});
const remoteConfig = firebase.remoteConfig();
// Only for development
remoteConfig.settings = {
  minimumFetchIntervalMillis: 3600000,
};

const FlagsProvider = ({ defaults, children }) => {
  const [flags, setFlags] = useState(defaults);
  useEffect(() => {
    remoteConfig.defaultConfig = defaults;
    remoteConfig
      .fetchAndActivate()
      .then((activated) => {
        //if (!activated) console.log("remoteconfig shouldn't be activated.");
        return remoteConfig.getAll();
      })
      .then((remoteFlags) => {
        const newFlags = {
          ...flags,
        };
        for (const [key, config] of Object.entries(remoteFlags)) {
          newFlags[key] = config._value;
        }
        setFlags(newFlags);
      })
      .catch((error) => console.error(error));
  }, [defaults, flags]);

  return (
    <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>
  );
};

export default FlagsProvider;

export const useFlags = () => {
  const context = useContext(FlagsContext);
  return context;
};
