import React, { useEffect } from "react";
import { firebase } from "./firebase";

// import AnchorLink from "react-anchor-link-smooth-scroll";
// import { Rnd } from "react-rnd";
// import { motion } from "framer-motion";
import CookieConsent from "react-cookie-consent";

import MiniCenteredFooter from "usedComponents/Footer.js";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container } from "components/misc/Layouts";
import GetStarted from "usedComponents/GetStarted.js";

import Header from "usedComponents/Header.js";
import TabGrid from "usedComponents/Products";

export default () => {
  // const previewImageAnimationVariants = {
  //   rest: {
  //     backgroundPositionY: "0%",
  //   },
  //   hover: {
  //     backgroundPositionY: "100%",
  //     transition: { type: "tween", ease: "linear", duration: 5 },
  //   },
  // };

  /**
   * Do this only once
   */
  useEffect(() => {}, []);

  const enableCookieServices = () => {
    // analytics
    firebase.analytics();
    firebase.analytics().logEvent("gelu_visit_products");
    // Initialize Performance Monitoring and get a reference to the service
    firebase.performance();

    console.log("Visited website");
    // pop-up privacy stuff
  };

  return (
    <AnimationRevealPage>
      <Container tw="bg-gray-100 -mx-8 -mt-8 pt-8 px-8">
        <CookieConsent
          debug={false}
          location="bottom"
          buttonText="Accept"
          enableDeclineButton
          declineButtonText="Decline"
          cookieName="gAnalytics"
          style={{
            background: "rgba(20, 30, 55, 0.95)",
            marginRight: "3vw",
            marginBottom: "3vh",
            width: "80vw",
            maxWidth: "420px",
            borderRadius: "8px",
            right: "0",
            left: "",
            overflow: "hidden",
          }}
          buttonStyle={{
            color: "#141E37",
            background: "#EEE",
            fontSize: "14px",
            borderRadius: "4px",
            marginTop: "0",
            float: "left",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
          declineButtonStyle={{
            color: "#EEE",
            background: "rgba(20, 30, 55, 0)",
            fontSize: "14px",
            marginTop: "0",
            float: "right",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
          onAccept={enableCookieServices}
        >
          This website uses cookies to analyze our traffic, we use basic Google
          Analytics implementation with anonymized data:{" "}
          <a id="cookie-link" href="/privacy">
            Privacy Policy
          </a>
        </CookieConsent>
        <Header />
        <TabGrid />
        <GetStarted />
        <MiniCenteredFooter />
      </Container>
    </AnimationRevealPage>
  );
};
