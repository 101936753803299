import React, {useState, useEffect} from 'react';
import Modal from 'react-modal';
import Yt from 'react-youtube';
import tw from 'twin.macro';

Modal.setAppElement('#root');

const Youtube = tw(Yt)`mt-10 lg:mt-16`;
const maxYoutubeWidth = 680;
const minYoutubeWidth = 150;
const youtubeSizeRatioLg = 2/5;
const youtubeSizeRatioSm = 3/5;
const LgBreakPoint = 1024 // Amount of pixels at Large breakpoint

const Title = tw.p`
  text-4xl font-bold mb-2 text-blue-900 tracking-wide self-start
`

const ExitButton = tw.div`
  absolute right-0 top-0 text-lg p-1 m-1 mr-3 cursor-pointer font-extrabold hover:bg-primary-100 rounded-full transition duration-200
`;

const Row = tw.div`
  flex flex-col-reverse justify-between lg:flex-row
`;

const Column = tw.div`
  flex flex-col items-center mx-5
`;
const BrochureButton = tw.a`
  cursor-pointer bg-primary-500 px-10 py-4 font-bold rounded-lg m-8 mt-20 text-white hover:bg-primary-900 transition duration-300
`;
const Description = tw.p`mb-5`

const Image = tw.img`
  rounded-lg max-w-md self-center
`;

export default function ProductModal({isOpen, onClose, name, brochureLink, imageSrc, ytId, description}) {
  const [size,setSize] = useState(getWindowDimensions());
  const ytOpts = determineYoutubeSize(size);

  useEffect(() => {
    function handleResize() {
      setSize(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  })

  const shouldDisplayYt = ytId != null;
  const modalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backdropFilter: 'blur(3px)'
    },
    content: {
      position: 'absolute',
      top: '100px',
      left: determineModalSize(size),
      right: determineModalSize(size),
      bottom: '100px',
      border: '2px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '5px',
      outline: 'none',
      padding: '20px'
    }
  }
  return (
      <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyles} >
        <Row>
          {shouldDisplayYt && 
          <Column>
            {<Youtube videoId={ytId} opts={ytOpts} />}
            {brochureLink != null && <BrochureButton href={brochureLink} target='_blank' rel ='noopener noreferrer'>Brochure</BrochureButton>}
          </Column>
          }
          <Column>
            <Title>{name}</Title>
            <Description>{description}</Description>
            {!shouldDisplayYt && brochureLink != null && <BrochureButton href={brochureLink} target='_blank' rel ='noopener noreferrer'>Brochure</BrochureButton>}
            <Image src={imageSrc}/>
          </Column>
        </Row>
        <ExitButton onClick={onClose}>
          ✖
        </ExitButton>
      </Modal>
  )
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function determineYoutubeSize({width}) {
  if (width > LgBreakPoint) {
    if (youtubeSizeRatioLg * width >= maxYoutubeWidth) return {width: maxYoutubeWidth, height: maxYoutubeWidth * 9/16}; 
    if (youtubeSizeRatioLg * width < minYoutubeWidth) return {width: minYoutubeWidth, height: minYoutubeWidth * 9/16}
    return {width: youtubeSizeRatioLg * width, height: youtubeSizeRatioLg * width * 9/16}
  } else{
    if (youtubeSizeRatioSm * width >= maxYoutubeWidth) return {width: maxYoutubeWidth, height: maxYoutubeWidth * 9/16}; 
    if (youtubeSizeRatioSm * width < minYoutubeWidth) return {width: minYoutubeWidth, height: minYoutubeWidth * 9/16}
    return {width: youtubeSizeRatioSm * width, height: youtubeSizeRatioSm * width * 9/16}
  }
}

function determineModalSize({width}) {
  return 0.25*width / 2 +'px';
}