import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import Modal from './ProductModal';

import LUXBAIRV2 from "images/products/Luxibel - B Air v2.png";
import LUXBDIRECT from "images/products/Luxibel - B Direct.png";
import LUXBDIRECT2 from "images/products/Luxibel - B Direct II.png";
import LUXBHYBRID from "images/products/Luxibel - B Hybrid.png";

import UVC100 from "images/products/UVC100.jpg";
import UVE220KUB from "images/products/UVE220KUB.jpg";
import UVF500M1 from "images/products/UVF500M1.jpg";
import UVF800M2 from "images/products/UVF800M2.jpg";
import UVF1000M3 from "images/products/UVF1000M3.jpg";
import UVH50 from "images/products/UVH50.jpg";
import UVK50 from "images/products/UVK50.jpg";
import UVK60 from "images/products/UVK60.jpg";
import UVK110 from "images/products/UVK110.jpg";
import UVO140 from "images/products/UVO140.jpg";
import UVO210 from "images/products/UVO210.jpg";
import UVQ110 from "images/products/UVQ110.png";
import UVT800M3 from "images/products/UVT800M3.jpg";

import UVF500M1BR from 'assets/uvf500m1.pdf';
import UVE220KUBBR from 'assets/uve220kub.pdf';
import UVE110KUBBR from 'assets/uve110kub.pdf';
import UVF800M2BR from 'assets/uvf800m2.pdf';
import UVF1000M3BR from 'assets/uvf1000m3.pdf';
import UVH50BR from 'assets/uvh50.pdf';
import UVCOMPACT from 'assets/uvkcompactair.pdf';
import UVO140BR from 'assets/uvo140.pdf';
import UVT800M3BR from 'assets/uvt800m3.pdf';
import LUXBAIRBR from 'assets/bair.pdf';
import LUXBDIRECTBR from 'assets/bdirect.pdf';
import LUXBDIRECT2BR from 'assets/bdirect2.pdf';
import LUXBHYBRIDBR from 'assets/bhybrid.pdf';

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${(props) => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(
  motion.div
)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(
  motion.div
)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  heading = "Producten",
  tabs = {
    "R-Filter": getRfilter(),
    Luxibel: getLux(),
  },
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalImage, setModalImage] = useState(null);
  const [ytId, setYtId] = useState(null);
  const [brochureLink, setBrochureLink] = useState(null);
  const [description, setDescription] = useState(null);

  return (
    <Container>
      <Modal 
        isOpen={modalOpen} 
        onClose={() => setModalOpen(false)}
        description={description} 
        name={modalTitle} 
        imageSrc={modalImage} 
        ytId={ytId} 
        brochureLink={brochureLink}/>
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>{heading}</Header>
          <TabsControl>
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl
                key={index}
                active={activeTab === tabName}
                onClick={() => setActiveTab(tabName)}
              >
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>
        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale: 1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale: 0.8,
                display: "none",
              },
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {tabs[tabKey].map((card, index) => (
              <CardContainer key={index}>
                <Card
                  className="group"
                  onClick={() => {
                    if (!card.ytId && !card.description) {
                      if (!card.url) {
                        window.open('/#contact')
                      } else {
                        window.open(card.url, '_blank');
                      }
                    } else {
                      setModalOpen(true);
                      setModalImage(card.imageSrc);
                      setModalTitle(card.title);
                      setYtId(card.ytId);
                      setBrochureLink(card.url);  
                      setDescription(card.description);
                    }
                  }}
                  initial="rest"
                  whileHover="hover"
                  animate="rest"
                >
                  <CardImageContainer imageSrc={card.imageSrc}>
                    <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: "auto",
                        },
                        rest: {
                          opacity: 0,
                          height: 0,
                        },
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <CardButton>Meer info</CardButton>
                    </CardHoverOverlay>
                  </CardImageContainer>
                  <CardText>
                    <CardTitle>{card.title}</CardTitle>
                    <CardContent>{card.content}</CardContent>
                  </CardText>
                </Card>
              </CardContainer>
            ))}
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

/* Template for a product:
  {
    imageSrc: The image to show
    title: ...
    content: extra text underneath the title
    url: The url to the brochure
    ytId: the id of the youtubevideo
    description: Extra text shown when clicking on the product
  }
*/

const getRfilter = () => {
  const cards = [
    {
      imageSrc: UVH50,
      title: "UVH50",
      content: "vloermodel",
      url: UVH50BR,
    },
    {
      imageSrc: UVE220KUB,
      title: "UVE 110KUB/C",
      content: "vloermodel",
      url: UVE110KUBBR,
    },
    {
      imageSrc: UVQ110,
      title: "UVQ110",
      ytId: 'juvThPOqjCI',
      content: "wand over airco",
    },
    {
      imageSrc: UVK50,
      title: "UVK 50",
      content: "in kanaal",
      url: UVCOMPACT,
    },
    {
      imageSrc: UVE220KUB,
      title: "UVE 220KUB/C",
      content: "vloermodel",
      url: UVE220KUBBR,
    },
    {
      imageSrc: UVK60,
      title: "UVK 60",
      content: "in kanaal",
      url: UVCOMPACT,
    },
    {
      imageSrc: UVO140,
      title: "UVO 140",
      content: "mobiel",
      url: UVO140BR,
    },
    {
      imageSrc: UVO210,
      title: "UVO 210",
      content: "mobiel",
    },
    {
      imageSrc: UVF500M1,
      title: "UVF500 M1",
      content: "kanaal rond",
      url: UVF500M1BR,
    },
    {
      imageSrc: UVK110,
      title: "UVK 110",
      content: "in kanaal",
      url: UVCOMPACT,
    },
    {
      imageSrc: UVT800M3,
      title: "UVT 800M3",
      content: "plafond onderbouw",
      url: UVT800M3BR,
    },
    {
      imageSrc: UVF800M2,
      title: "UVF 800M2",
      content: "kanaal rond",
      url: UVF800M2BR,
    },
    {
      imageSrc: UVF1000M3,
      title: "UVF 1000M3",
      content: "kanaal rond",
      url: UVF1000M3BR,
    },
    {
      imageSrc: UVC100,
      title: "UVC100",
      content: "draagbaar",
    },
  ];

  // Shuffle array
  return cards;
};

const getLux = () => {
  const cards = [
    {
      imageSrc: LUXBDIRECT2,
      title: "B DIRECT II",
      content: "Opbouw wandmodel",
      url: LUXBDIRECT2BR,
      ytId: '2AsYJf8KOLI'
    },
    {
      imageSrc: LUXBHYBRID,
      title: "B HYBRID",
      content: "Opbouw wandmodel",
      url: LUXBHYBRIDBR,
      ytId: '2AsYJf8KOLI'
    },
    {
      imageSrc: LUXBDIRECT,
      title: "B DIRECT",
      content: "Opbouw wandmodel",
      url: LUXBDIRECTBR,
      ytId: '2AsYJf8KOLI'
    },
    {
      imageSrc: LUXBAIRV2,
      title: "B AIR-V2",
      content: "Opbouw wandmodel",
      url: LUXBAIRBR,
      ytId: '2AsYJf8KOLI'
    },
  ];

  return cards;
};
