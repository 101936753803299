import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion.custom(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion.custom(tw.dd`text-sm sm:text-base leading-relaxed`);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  subheading = "FAQS",
  heading = "Nog vragen?",
  description = "Wij hebben de antwoorden om u bij te staan! Contacteer ons voor meer info.",
  faqs = [
    {
      question: "Werkt UV-C tegen corona",
      answer:
        "De Phillips UVC lampen hebben een bewezen effect volgens de universiteit van Boston, Gent. U kan het artikel hier lezen. (links) “UV-C, een vorm van ultraviolette straling die micro-organismen kan doden, is volgens wetenschappers van de Boston University bewezen effectief bij het uitschakelen van het nieuwe coronavirus. In tests concludeerden ze dat de UVstraling uit lampen van Signify bij een specifieke dosering in zes seconden 99 procent van het virus had weggevaagd.",
    },
    {
      question: "Wat is UV-C licht",
      answer:
        "Dit is een vorm van ultraviolet licht dat niet op Aarde voorkomt, aangezien de ozonlaag dit tegenhoudt. De golflengte van 200-280 nm veroorzaakt na enkele seconden aanzienlijke DNA schade aan micro-organismen, zoals virussen en bacteriën. ",
    },
    {
      question: "Is UV-C veilig voor mensen",
      answer:
        "UV-C straling inactiveert of beschadigt het DNA en RNA van micro-organismen op een efficiënte manier. Hierdoor is dit ook schadelijk voor menselijk contact met de huid of ogen. Bij de meeste toepassingen zijn daarom de lampen ingebouwd zodat dit veilig is voor de aanwezige personen, bij open toepassingen zijn er bewegingsmelders en signalen aanwezig om mensen hiervoor te beschermen.",
    },
    {
      question: "Waar moet ik dit plaatsen",
      answer:
        "Afhankelijk van het type gebouw plaatst men dit in ruimtes waar de meeste bezetting is. Vooral ruimtes waar klanten worden ontvangen zoals vergaderzalen, wachtruimtes en winkels. Onze adviseurs kiezen steeds de optimale oplossing prijs/kwaliteit en doeltreffendheid.",
    },
    {
      question: "Is deze technologie nieuw?",
      answer: (
        <>
          <p>
            Nee, in tegenstelling tot wat veel mensen denken, bestaat deze
            technologie al meer dan 100 jaar. UV-C technologie wordt al sinds de
            late 19 de eeuw in haar basisvorm toegepast bij de bestrijding van
            onder meer tuberculose.
          </p>
          <br></br>
          <p>Een les UV-geschiedenis:</p>
          <br></br>

          <p>
            De Deense arts Niels Ryberg Finsen (M.D, University of Copenhagen)
            was in de jaren 1890 een van de eersten die de UV-technologie
            onderzocht voor toepassing op verscheidene ziektebeelden. Finsen
            lijdde aan Niemann-Pick, een zeldzame genetische aandoening waardoor
            hij last had van bloedarmoede en erg vaak vermoeid was. De ziekte
            dwong hem om zijn laatste levensjaren door te brengen in een
            rolstoel. Zijn eigen gezondheidsproblemen triggerden in hem de
            zoektocht naar welzijn:
          </p>
          <br></br>

          <p>
            Finsen’s woning was noord-georiënteerd, en hij vroeg zich hierbij af
            of meer blootstelling aan zonlicht hem kon helpen zijn symptomen te
            verlichten. Hij ging zich als overtuigd wetenschapper verdiepen in
            de studies van zon- en kunstmatig licht. Al snel merkte hij de
            effectiviteit op van kunstmatig ultraviolet licht, de soort die niet
            in haar natuurlijke vorm voorkomt op aarde dus.
          </p>
          <br></br>

          <p>
            Eerder toevallig ging Finsen zich focussen op de ziekte lupus
            vulgaris: in 1895 kon hij gebruik maken van het labo van The
            Copenhagen Electric Works. Een van de ingenieurs die daar
            tewerkgesteld was, leed aan de voorgenoemde aandoening. Lupus
            vulgaris, een huidaandoening, wordt verzoorzaakt door dezelfde soort
            bacterie als tuberculose. Al na een behandeling van enkele dagen met
            kunstmatig licht merkte men verbetering in de aandoening van de
            ingenieur. Hierna volgden er verscheidene studies om de
            effectiviteit van deze behandeling verder te bewijzen. Al snel werd
            de technologie van Finsen meer en meer toegepast voor de behandeling
            van uiteenlopende medische aandoeningen.
          </p>
          <br></br>

          <p>
            In 1903 ontving Finsen de Nobelprijs voor Geneeskunde als erkenning
            voor zijn geleverde onderzoeksresultaten en meerwaarde voor de
            samenleving.
          </p>
        </>
      ),
    },
  ],
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container id={"faq"}>
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
          </HeaderContent>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 },
                    }}
                    initial="collapsed"
                    animate={
                      activeQuestionIndex === index ? "open" : "collapsed"
                    }
                    transition={{
                      duration: 0.02,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" },
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer}
                  {<br></br>}
                  {faq.question.toLowerCase().includes("corona") ? (
                    <Link
                      href="/artikel.pdf"
                      target="_blank"
                      onClick={() => console.log("TEST")}
                    >
                      Lees het artikel.
                    </Link>
                  ) : null}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
