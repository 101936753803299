import React, { useState, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import MailchimpSubscribe from "react-mailchimp-subscribe"
//eslint-disable-next-line
import { css } from "styled-components/macro";
// import { firebase, db } from "../firebase";
import { useFlags } from "../FlagsProvider";
// import { Trans } from "react-i18next";
// import i18n from "../../i18n";

import Header from "./Header";

import { ReactComponent as SvgDecoratorBlob1 } from "../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../images/UV-C auto.jpeg";
import CustomersLogoStripImage from "../images/partners.png";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`max-w-full	relative z-40 transform flex justify-center items-start`;
const Image = tw.img`object-right w-8/12 sm:rounded lg:rounded-lg relative z-20 float-right shadow-2xl ml-5 `;


// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;

const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
// const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;

const CustomForm = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState("");

  const submit = () => {
    if(email && checkEmailValidity(email)) {
      onValidated({
        EMAIL: email,
      });
      setEmail("");
      window.alert("Tot binnenkort!")
    }
  };
 

    return (        
    <Actions>
      <input
        value={email}
        type="text"
        id="input-1"
        // ref={node => (email = node)}
        onChange={(event) => {
          setEmail(event.target.value);
        }}
        placeholder="Mijn email-adres"
      />
      <button onClick={submit}>Schrijf me in</button>
    </Actions>

    ) ;

      };

const checkEmailValidity = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};


export default ({ roundedHeaderButton }) => {
  //states

  const { gelu_title } = useFlags();






  useEffect(() => {
    console.log(gelu_title);
  }, [gelu_title]);


  
  
   // render
  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container id="top">
        <TwoColumn>
          <LeftColumn>
            <Heading>
            
 <HighlightedText>Dé UV-C oplossing </HighlightedText> voor in de wagen!                          
            </Heading>
            <Paragraph>
              Maak kans op dit product, door je email-adres in te vullen. <br/><i>We beschermen uw gegevens</i>.
            </Paragraph>
            <MailchimpSubscribe
    url={"https://gezondekantoorlucht.us7.list-manage.com/subscribe/post?u=49a4a37cd15eb16e7087bd436&amp;id=c390720353"}
    render={({ subscribe, status, message }) => (
      <div>
        <CustomForm status={status}
              message={message}
              onValidated={formData => subscribe(formData)}></CustomForm>
      </div>
    )}
  />
            <CustomersLogoStrip>
              <p>Onze vertrouwde partners</p>
              <img src={CustomersLogoStripImage} alt="Our Customers" />
            </CustomersLogoStrip>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <Image
                src={DesignIllustration}
                alt="Design Illustration"
              />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
