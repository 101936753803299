import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";
// import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon } from "images/linkedin-icon.svg";
// import { ReactComponent as GithubIcon } from "images/github-icon.svg";

// images profiles
import wesley from "images/wesley.jpg";
import soufyan from "images/soufyan.jpeg";
import jona from "images/jona.jpg";
import zouheir from "images/zouheir.jpeg";
import jolien from "images/jolien.png";
import enrico from "images/enrico.png";

const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`mx-auto text-center`;

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`;
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center`;
const CardImage = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`w-64 h-64 bg-contain bg-center rounded`}
`;
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  9
`;

const CardLinks = styled.div`
  ${tw`mt-6 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-gray-400 hocus:text-primary-500 transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`;

export default ({
  heading = "We staan volledig voor u klaar.",
  subheading = "Het team",
  description = "Administratief klein, maar met een team techniekers over heel Vlaanderen.",
  cards = [
    {
      imageSrc: wesley,
      position: "Zaakvoerder",
      name: "Wesley",
      links: [
        {
          url: "https://www.linkedin.com/in/wesley-polling-394a6538/",
          icon: LinkedinIcon,
        },
      ],
    },
    {
      imageSrc: soufyan,
      position: "Sales",
      name: "Soufyan",
      links: [
        {
          url: "https://www.linkedin.com/in/soufyan-hadra-117a2a119/",
          icon: LinkedinIcon,
        },
      ],
    },
    {
      imageSrc: zouheir,
      position: "Planning",
      name: "Zouheir ",
      links: [],
    },
    {
      imageSrc: jolien,
      position: "Marketing",
      name: "Jolien",
      links: [
        {
          url: "https://www.linkedin.com/in/jolien-bruyninckx-5a7b3275/",
          icon: LinkedinIcon,
        },
      ],
    },
    {
      imageSrc: enrico,
      position: "Sales",
      name: "Enrico",
      links: [
        {
          url: "https://www.linkedin.com/in/enrico-popowski-07570a162/",
          icon: LinkedinIcon,
        },
      ],
    },

    {
      imageSrc: jona,
      position: "IT",
      name: "Jona",
      links: [
        {
          url: "https://www.linkedin.com/in/jona-halffman-178a8099/",
          icon: LinkedinIcon,
        },
      ],
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading>}
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <Cards>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <CardContent>
                <span className="position">{card.position}</span>
                <span className="name">{card.name}</span>
                <CardLinks>
                  {card.links.map((link, linkIndex) => (
                    <a key={linkIndex} className="link" href={link.url}>
                      <link.icon className="icon" />
                    </a>
                  ))}
                </CardLinks>
              </CardContent>
            </Card>
          ))}
        </Cards>
      </ContentWithPaddingXl>
    </Container>
  );
};
