import React, { useEffect } from "react";
import { firebase } from "./firebase";

// import AnchorLink from "react-anchor-link-smooth-scroll";
// import { Rnd } from "react-rnd";
// import { motion } from "framer-motion";
import CookieConsent from "react-cookie-consent";

// import { components } from "ComponentRenderer.js";
import Hero from "usedComponents/Hero.js";
import AutoHero from "usedComponents/AutoHero.js";

import WithStepsAndImageFeatures from "usedComponents/Steps.js";

import ProfileThreeColGridCards from "usedComponents/Team.js";
// import TwoColumnWithImageAndRatingTestimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FeatureCircles from "usedComponents/Features.js";
import FAQ from "usedComponents/FAQ.js";
import Gallery from "usedComponents/Gallery.js";

// import Features from "components/features/VerticalWithAlternateImageAndText.js";
import MainFeature2 from "usedComponents/MainFeature2";
import MainFeature from "usedComponents/MainFeature";
import MainFeatureGreenX from "usedComponents/MainFeatureGreenX";
import Testimonials from "usedComponents/Testimonials";

// import TwoColContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import TwoColContactUsForm from "usedComponents/Contact.js";
import MiniCenteredFooter from "usedComponents/Footer.js";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container } from "components/misc/Layouts";
import GetStarted from "usedComponents/GetStarted.js";
import { domain } from "domainConfig";
import MainFeatureAuto from "usedComponents/MainFeatureAuto";

export default ({ features = null }) => {
  /**
   * Do this only once
   */
  useEffect(() => {
    console.log(domain);
  }, []);

  const enableCookieServices = () => {
    // analytics
    firebase.analytics();
    firebase.analytics().logEvent("gelu_visit");
    window.dataLayer.push({
      event: "pageview",
    });

    // Initialize Performance Monitoring and get a reference to the service
    firebase.performance();

    console.log("Visited website");
  };

  const displayAuto = domain.includes("auto");

  return (
    <AnimationRevealPage>
      <Container tw="bg-gray-100 -mx-8 -mt-8 pt-8 px-8">
        <CookieConsent
          debug={false}
          location="bottom"
          buttonText="Accept"
          enableDeclineButton
          declineButtonText="Decline"
          cookieName="gAnalytics"
          style={{
            background: "rgba(20, 30, 55, 0.95)",
            marginRight: "3vw",
            marginBottom: "3vh",
            width: "80vw",
            maxWidth: "420px",
            borderRadius: "8px",
            right: "0",
            left: "",
            overflow: "hidden",
          }}
          buttonStyle={{
            color: "#141E37",
            background: "#EEE",
            fontSize: "14px",
            borderRadius: "4px",
            marginTop: "0",
            float: "left",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
          declineButtonStyle={{
            color: "#EEE",
            background: "rgba(20, 30, 55, 0)",
            fontSize: "14px",
            marginTop: "0",
            float: "right",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
          onAccept={enableCookieServices}
        >
          This website uses cookies to analyze our traffic, we use basic Google
          Analytics implementation with anonymized data:{" "}
          <a id="cookie-link" href="/privacy">
            Privacy Policy
          </a>
        </CookieConsent>
        {!displayAuto && <Hero />}
        {displayAuto && <AutoHero />}
        {displayAuto && <MainFeatureAuto />}
        <WithStepsAndImageFeatures />
        <MainFeature />
        <MainFeature2 />
        <MainFeatureGreenX />
        <FeatureCircles />
        <GetStarted />
        <Gallery />
        <Testimonials />
        <FAQ />
        <ProfileThreeColGridCards />
        <TwoColContactUsForm />
        <MiniCenteredFooter />
      </Container>
    </AnimationRevealPage>
  );
};
