import React from "react";
import { useState } from "react";

import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import { firebase, db } from "../firebase";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;
const Link = tw.a`inline-block text-lg text-secondary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500`;

export default ({
  subheading = "Contacteer ons",
  heading = (
    <>
      Voel je vrij om met ons <span tw="text-primary-500">in contact</span>
      <wbr /> te komen.
    </>
  ),
  description = (
    <>
      <Link href="tel:+3211361005">
        <span role="img" aria-label="call">
          📞
        </span>{" "}
        011 36 10 05
      </Link>
    </>
  ),
  submitButtonText = "Verstuur",
  textOnLeft = true,
}) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const checkEmailValidity = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };


  // onclick function
  function addEmail() {
    console.log("Pushed button");

    if (!checkEmailValidity(email)) {
      window.alert("Ongeldige email");
      return;
    }
  

    firebase.analytics();
    firebase.analytics().logEvent("gelu_contact");

    // firestore push to database
    db.collection("gelu_mails")
      .add({ email: email, name: name, phone: phone, message: message })
      .then(() => window.alert("We contacteren u dadelijk!"))
      .catch((er) => {
        console.log(er);
        window.alert(
          "Er ging iets mis! Probeer ons best telefonisch te bereiken."
        );
      });

    // Remove email
    setEmail("");
    setName("");
    setPhone("");
    setMessage("");
  }

  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container id={"contact"}>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form>
              <Input
                type="email"
                placeholder="Email adres"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
              <Input
                type="text"
                placeholder="Naam"
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />
              <Input
                type="text"
                placeholder="GSM nummer"
                value={phone}
                onChange={(event) => {
                  setPhone(event.target.value);
                }}
              />
              <Textarea
                placeholder="Uw bericht"
                value={message}
                onChange={(event) => {
                  setMessage(event.target.value);
                }}
              />
              <SubmitButton onClick={addEmail}>{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
