import Firebase from "firebase/app";

// Used for a/b testing: read-only database
import "firebase/remote-config";
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Performance Monitoring library
import "firebase/performance";

// Add the Performance Monitoring library
import "firebase/firestore";

export const firebaseConfig = {
  apiKey: "AIzaSyBwBoRVi-10Xlh_F90Ud5AmZPJSyh80l04",
  authDomain: "kolu-v2.firebaseapp.com",
  databaseURL: "https://kolu-v2.firebaseio.com",
  projectId: "kolu-v2",
  storageBucket: "kolu-v2.appspot.com",
  messagingSenderId: "904940067182",
  appId: "1:904940067182:web:b326729b74b2a38fcd497f",
  measurementId: "G-C7SM9KHNG7",
};
export const firebase = Firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
